import React from 'react';
import './services.css';
import { BsCheck } from 'react-icons/bs';

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>UI/UX Desgin</h3>
          </div>
          <ul className="service__list">
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
          </ul>
        </article>
        {/* END OF Web Development */}
        <article className='service'>
          <div className="service__head">
            <h3>Content Ceration</h3>
          </div>
          <ul className="service__list">
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon' />
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veniam, dolor.</p>
            </li>
          </ul>
        </article>
        {/* END OF Content Ceration */}
      </div>
    </section>
  )
}

export default Services